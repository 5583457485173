<template>
  <section>
    <div class="title">
      <h2 id="price">料金</h2>
      <img src="@/assets/title-bar.webp" class="title-bar">
      <img src="@/assets/ira004.webp" class="ira-img">
    </div>

    <h3>従量電灯 生活安心プラン</h3>
    <div class="wrap pt-80">
      <div class="detail">
        <h4>
          電気料金内訳
          <hr>
        </h4>
        <div class="detail-contents">
          <div class="detail-content">基本料金</div>
          <div class="plus">+</div>
          <div class="detail-content">電力量料金<br><span class="description-text">（電源料金+固定従量料金×電気使用量）</span></div>
          <div class="plus">+</div>
          <div class="detail-content">再生可能エネルギー<br>発電賦課金</div>
          <img src="@/assets/ira005.webp" class="ira-img">
        </div>
      </div>
      <p class="annotation">※ガス料金とのセット割引は準備中です。</p>
    </div>

    <h3>基本料金が安くなる項目</h3>
    <div class="wrap pt-harf">
      <div class="comparison-area">
        <div class="area">
          <h4>東京電力エリア</h4>
          <div class="area-table">
            <div class="row basic">
              <div class="content fw-500">基本料金</div>
              <div class="description">935.25円</div>
              <div class="point-space"></div>
            </div>
            <div class="row toshigas">
              <div class="content">都市ガス電気</div>
              <div class="description">692.01円</div>
              <div class="point-space">
                <p>26.0%</p>
                <img src="@/assets/pa004.webp" class="point-img">
              </div>
            </div>
          </div>
        </div>
        <div class="area">
          <h4>関西電力エリア</h4>
          <div class="area-table">
            <div class="row basic">
              <div class="content fw-500">基本料金</div>
              <div class="description">522.58円</div>
              <div class="point-space"></div>
            </div>
            <div class="row toshigas">
              <div class="content">都市ガス電気</div>
              <div class="description">290.4円</div>
              <div class="point-space">
                <p>44.4%</p>
                <img src="@/assets/pa004.webp" class="point-img">
              </div>
            </div>
          </div>
        </div>
        <div class="area">
          <h4>九州電力エリア</h4>
          <div class="area-table">
            <div class="row basic">
              <div class="content fw-500">基本料金</div>
              <div class="description">948.72円</div>
              <div class="point-space"></div>
            </div>
            <div class="row toshigas">
              <div class="content">都市ガス電気</div>
              <div class="description">682.14円</div>
              <div class="point-space">
                <p>28.1%</p>
                <img src="@/assets/pa004.webp" class="point-img">
              </div>
            </div>
          </div>
        </div>
        <div class="area">
          <h4>中部電力エリア</h4>
          <div class="area-table">
            <div class="row basic">
              <div class="content fw-500">基本料金（30A）</div>
              <div class="description">963.42円</div>
              <div class="point-space"></div>
            </div>
            <div class="row toshigas">
              <div class="content">都市ガス電気</div>
              <div class="description">643.5円</div>
              <div class="point-space">
                <p>33.2%</p>
                <img src="@/assets/pa004.webp" class="point-img">
              </div>
            </div>
          </div>
        </div>
        <div class="area">
          <h4>中国電力エリア</h4>
          <div class="area-table">
            <div class="row basic">
              <div class="content fw-500">基本料金（30A）</div>
              <div class="description">669.92円</div>
              <div class="point-space"></div>
            </div>
            <div class="row toshigas">
              <div class="content">都市ガス電気</div>
              <div class="description">326.7円</div>
              <div class="point-space">
                <p>51.2%</p>
                <img src="@/assets/pa004.webp" class="point-img">
              </div>
            </div>
          </div>
        </div>
        <div class="area">
          <h4>四国電力エリア</h4>
          <div class="area-table">
            <div class="row basic">
              <div class="content fw-500">基本料金</div>
              <div class="description">666.89円</div>
              <div class="point-space"></div>
            </div>
            <div class="row toshigas">
              <div class="content">都市ガス電気</div>
              <div class="description">363.0円</div>
              <div class="point-space">
                <p>45.6%</p>
                <img src="@/assets/pa004.webp" class="point-img">
              </div>
            </div>
          </div>
        </div>
        <div class="area">
          <h4>北海道電力エリア</h4>
          <div class="area-table">
            <div class="row basic">
              <div class="content fw-500">基本料金（30A）</div>
              <div class="description">1207.8円</div>
              <div class="point-space"></div>
            </div>
            <div class="row toshigas">
              <div class="content">都市ガス電気</div>
              <div class="description">828.3円</div>
              <div class="point-space">
                <p>31.4%</p>
                <img src="@/assets/pa004.webp" class="point-img">
              </div>
            </div>
          </div>
        </div>
        <div class="area">
          <h4>東北電力エリア</h4>
          <div class="area-table">
            <div class="row basic">
              <div class="content fw-500">基本料金（30A）</div>
              <div class="description">1108.8円</div>
              <div class="point-space"></div>
            </div>
            <div class="row toshigas">
              <div class="content">都市ガス電気</div>
              <div class="description">679.8円</div>
              <div class="point-space">
                <p>38.7%</p>
                <img src="@/assets/pa004.webp" class="point-img">
              </div>
            </div>
          </div>
        </div>
        <div class="area">
          <h4>北陸電力エリア</h4>
          <div class="area-table">
            <div class="row basic">
              <div class="content fw-500">基本料金（30A）</div>
              <div class="description">907.5円</div>
              <div class="point-space"></div>
            </div>
            <div class="row toshigas">
              <div class="content">都市ガス電気</div>
              <div class="description">726円</div>
              <div class="point-space">
                <p>20.0%</p>
                <img src="@/assets/pa004.webp" class="point-img">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3>保険サービス</h3>
    <div class="wrap pt-0">
      <div class="insurance">
        <p class="insurance-message">
          「従量電灯 生活安心プラン」をお申し込みいただいたお部屋とお客様には、嬉しい保証特典が付いています！
        </p>
        <div class="insurance-list">
          <div class="service">
            <p class="announce-text"><span>月額<br />500<span class="yen">円</span></span></p>
            <div class="service-img">
              <img src="@/assets/icon007.webp" alt="一人暮らしの生活をサポート">
            </div>
            <h4 class="service-title">一人暮らし生活サポート</h4>
            <p>
              入居中の鍵の紛失、水回りのトラブル、ガラスの破損トラブル、ガス、給湯器のトラブルの解決を専用フリーダイヤルで24時間365日サポートします！
              都市ガス電気をお申込みいただいたお客様限定で、無料期間がございます。
              電気スイッチング月を1ヶ月目として、翌月末までの最大2ヶ月間がサービス無料期間となります！
              初回請求のタイミングは、電気サービスのスイッチングから2回目の電気料金支払い時に、電気料金と合算で請求をさせていただきます。
            </p>
          </div>
          <div class="service">
            <p class="announce-text">永年<br />無料</p>
            <div class="service-img">
              <img src="@/assets/icon008.webp" alt="一人暮らしでも安心のサポート">
            </div>
            <h4 class="service-title">一人暮らし安心サポート</h4>
            <p>入居中のマンションでの近隣トラブルや不法侵入、ストーカー被害に関して、専門家から対応のアドバイスをし、トラブル解決のサポートをします！悪質かつ継続的な嫌がらせ行為や不法侵入、ストーカー被害で住居転移される場合は10万円まで転居費用の保証も！</p>
          </div>
        </div>
      </div>
    </div>

    <h3>各電力会社様との料金の比較</h3>
    <div class="wrap pt-80">
      <div class="residential-area">
        <h4>
          お住まいの地域
          <hr>
        </h4>
        <div class="area-btn-list">
          <button type="button" class="area-btn" v-scroll-to="'#comparison'" @click="selectArea('tokyo')" :class="{'active': this.select === 'tokyo'}">
            <span>東京エリア</span><span>▼</span>
          </button>
          <button type="button" class="area-btn" v-scroll-to="'#comparison'" @click="selectArea('kansai')" :class="{'active': this.select === 'kansai'}">
            <span>関西エリア</span><span>▼</span>
          </button>
          <button type="button" class="area-btn" v-scroll-to="'#comparison'" @click="selectArea('kyushu')" :class="{'active': this.select === 'kyushu'}">
            <span>九州エリア</span><span>▼</span>
          </button>
          <button type="button" class="area-btn" v-scroll-to="'#comparison'" @click="selectArea('chubu')" :class="{'active': this.select === 'chubu'}">
            <span>中部エリア</span><span>▼</span>
          </button>
          <button type="button" class="area-btn" v-scroll-to="'#comparison'" @click="selectArea('chugoku')" :class="{'active': this.select === 'chugoku'}">
            <span>中国エリア</span><span>▼</span>
          </button>
          <button type="button" class="area-btn" v-scroll-to="'#comparison'" @click="selectArea('shikoku')" :class="{'active': this.select === 'shikoku'}">
            <span>四国エリア</span><span>▼</span>
          </button>
          <button type="button" class="area-btn" v-scroll-to="'#comparison'" @click="selectArea('hokkaido')" :class="{'active': this.select === 'hokkaido'}">
            <span>北海道エリア</span><span>▼</span>
          </button>
          <button type="button" class="area-btn" v-scroll-to="'#comparison'" @click="selectArea('tohoku')" :class="{'active': this.select === 'tohoku'}">
            <span>東北エリア</span><span>▼</span>
          </button>
          <button type="button" class="area-btn" v-scroll-to="'#comparison'" @click="selectArea('hokuriku')" :class="{'active': this.select === 'hokuriku'}">
            <span>北陸エリア</span><span>▼</span>
          </button>
        </div>

        <h4 id="comparison">
          {{ this.selectItem.title }}との比較
          <hr>
        </h4>
        <div class="toshigas-title">都市ガス電気</div>
        <div class="toshigas-list">
          <div class="toshigas-basic-charge">
            <div class="title">基本料金</div>
            <div class="description">
              <span class="price">{{ this.selectItem.toshigasPrice }}円</span>
              <img src="@/assets/pa002.webp" class="discount-img">
              <span>
                {{ this.selectItem.toshigasDiscount }}
              </span>
            </div>
          </div>
          <div class="toshigas-electricity-charge">
            <div class="title">電力量料金</div>
            <div class="description">電源料金 <br class="sp-only">＋<br class="sp-only"> 固定従量料金 <br class="sp-only">×<br class="sp-only"> 電気使用量</div>
          </div>
        </div>
        <div class="other-title">{{ this.selectItem.title }}</div>
        <div class="other-list">
          <div class="other-basic-charge">
            <div class="title">基本料金</div>
            <div class="description">
              {{ this.selectItem.price }}円
            </div>
          </div>
          <div class="other-electricity-charge">
            <div class="title">電力量料金</div>
            <div class="description">
              <div class="row border">
                <div class="energy">{{ this.selectItem.firstEnergy }}kwh</div>
                <div class="price">{{ this.selectItem.firstPrice }}円</div>
              </div>
              <div class="row border">
                <div class="energy">{{ this.selectItem.seccondEnergy }}kwh</div>
                <div class="price">{{ this.selectItem.seccondPrice }}円</div>
              </div>
              <div class="row">
                <div class="energy">{{ this.selectItem.thirdEnergy }}kwh</div>
                <div class="price">{{ this.selectItem.thirdPrice }}円</div>
              </div>
            </div>
          </div>
        </div>
        <div class="breach-content">
          <h4 class="breach-title">違約金について</h4>
          <p>供給開始日以降 2年以内に電気需給契約を終了される場合には、 1 契約につき 19,800円（税込）の解約手数料が発生いたします。</p>
        </div>
      </div>
    </div>

    <h3>従量電灯 家族プラン</h3>
    <div class="wrap pb-0 pt-80">
      <div class="detail">
        <h4>
          電気料金内訳
          <hr>
        </h4>
        <div class="detail-contents">
          <div class="detail-content">基本料金</div>
          <div class="plus">+</div>
          <div class="detail-content">電力量料金<br><span class="description-text">（電源料金+固定従量料金×電気使用料）</span></div>
          <div class="plus">+</div>
          <div class="detail-content">再生可能エネルギー<br>発電賦課金</div>
          <img src="@/assets/ira005.webp" class="ira-img-right">
        </div>
      </div>
      <p class="annotation">※ガス料金とのセット割引は準備中です。</p>
      <ComparisonTokyo />
      <DemeritVue />
    </div>
  </section>
</template>

<script>
import ComparisonTokyo from '@/components/Ui/ComparisonTokyo.vue';
import DemeritVue from '@/components/Home/Demerit.vue';

export default {
  name: "PriceVue",
  components: {
    ComparisonTokyo,
    DemeritVue,
  },
  data() {
    return {
      area: 1,
      select: "tokyo",
      selectItem: {},
      list: {
        tokyo : {
          title: "東京電力",
          toshigasPrice: "692.01",
          toshigasDiscount: "26.0%",
          price: "935.25",
          firstEnergy: "16〜120",
          firstPrice: "29.80",
          seccondEnergy: "121〜300",
          seccondPrice: "36.40",
          thirdEnergy: "301〜",
          thirdPrice: "40.49",
        },
        kansai : {
          title: "関西電力",
          toshigasPrice: "290.4",
          toshigasDiscount: "44.4%",
          price: "522.58",
          firstEnergy: "16〜120",
          firstPrice: "20.21",
          seccondEnergy: "121〜300",
          seccondPrice: "25.61",
          thirdEnergy: "301〜",
          thirdPrice: "28.59",
        },
        kyushu : {
          title: "九州電力",
          toshigasPrice: "682.14",
          toshigasDiscount: "28.1%",
          price: "948.72",
          firstEnergy: "16〜120",
          firstPrice: "18.37",
          seccondEnergy: "121〜300",
          seccondPrice: "23.97",
          thirdEnergy: "301〜",
          thirdPrice: "25.87",
        },
        chubu : {
          title: "中部電力",
          toshigasPrice: "643.5",
          toshigasDiscount: "33.2%",
          price: "963.42",
          firstEnergy: "16〜120",
          firstPrice: "21.20",
          seccondEnergy: "121〜300",
          seccondPrice: "25.67",
          thirdEnergy: "301〜",
          thirdPrice: "28.62",
        },
        chugoku : {
          title: "中国電力",
          toshigasPrice: "326.7",
          toshigasDiscount: "51.2%",
          price: "669.92",
          firstEnergy: "16〜120",
          firstPrice: "32.01",
          seccondEnergy: "121〜300",
          seccondPrice: "39.43",
          thirdEnergy: "301〜",
          thirdPrice: "41.55",
        },
        shikoku : {
          title: "四国電力",
          toshigasPrice: "363.0",
          toshigasDiscount: "45.6%",
          price: "666.89",
          firstEnergy: "16〜120",
          firstPrice: "30.65",
          seccondEnergy: "121〜300",
          seccondPrice: "37.27",
          thirdEnergy: "301〜",
          thirdPrice: "38.58",
        },
        hokkaido : {
          title: "北海道電力",
          toshigasPrice: "828.3",
          toshigasDiscount: "31.4%",
          price: "1207.8",
          firstEnergy: "16〜120",
          firstPrice: "35.35",
          seccondEnergy: "121〜280",
          seccondPrice: "41.64",
          thirdEnergy: "281〜",
          thirdPrice: "45.36",
        },
        tohoku : {
          title: "東北電力",
          toshigasPrice: "679.8",
          toshigasDiscount: "38.7%",
          price: "1108.8",
          firstEnergy: "16〜120",
          firstPrice: "29.62",
          seccondEnergy: "121〜300",
          seccondPrice: "36.37",
          thirdEnergy: "301〜",
          thirdPrice: "40.32",
        },
        hokuriku : {
          title: "九州電力",
          toshigasPrice: "726.0",
          toshigasDiscount: "20.0%",
          price: "907.5",
          firstEnergy: "16〜120",
          firstPrice: "30.86",
          seccondEnergy: "121〜300",
          seccondPrice: "34.75",
          thirdEnergy: "301〜",
          thirdPrice: "36.46",
        },
      }
    }
  },
  mounted() {
    console.log(this.select);

    this.selectArea("tokyo");
  },
  methods: {
    selectArea(area) {
      this.selectItem = this.list[area];
      this.select = area;
      console.log(this.selectItem);
    }
  },
}
</script>

<style lang="scss" scoped>
  section {
    .title {
      position: relative;
      margin-bottom: 60px;
      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
      .ira-img {
        position: absolute;
        top: -30px;
        right: 10%;
        width: 100px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          right: 5%;
          width: 80px;
        }
        @media screen and (max-width: 767px) {
          right: 5%;
          width: 80px;
        }
      }
    }
    h3 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      width: 100%;
      font-size: 25px;
      font-weight: 500;
      color: var(--white);
      background-color: var(--main);
      @media screen and (max-width: 1100px) {
        font-size: 20px;
      }
    }
    .wrap {
      padding: 130px 0;
      @media screen and (max-width: 1100px) {
        padding: 70px 20px;
      }
      // 表
      .detail {
        padding: 40px 40px;
        border: 5px solid var(--main);
        border-radius: 40px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          padding: 60px 15px;
        }
        @media screen and (max-width: 767px) {
          padding: 40px 10px;
        }
        h4 {
          font-size: 25px;
          font-weight: 400;
          text-align: center;
          line-height: 30px;
          margin: 0;
          color: var(--font);
          @media screen and (max-width: 1100px) {
            font-size: 20px;
            font-weight: 500;
          }
          hr {
            width: 45px;
            margin: 20px auto 0 auto;
            border-color: var(--main);
            @media screen and (max-width: 1100px) {
              margin: 10px auto 30px auto;
            }
          }
        }
        .detail-contents {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 40px;
          @media screen and (max-width: 767px) {
            display: block;
          }
          .detail-content {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;
            min-width: 25%;
            height: 90px;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            padding: 0;
            margin: 0;
            background-color: #8DDFF7;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              font-size: 18px;
            }
            @media screen and (max-width: 767px) {
              height: 70px;
              font-size: 20px;
              line-height: 25px;
            }
            .description-text {
              width: 100%;
              text-align: center;
              font-size: 14px;
              margin: 0;
              @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 16px;
                margin-top: 5px;
              }
              @media screen and (max-width: 350px) {
                font-size: 12px;
              }
            }
          }
          .plus {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 5%;
            font-size: 30px;
            font-weight: 600;
            @media screen and (max-width: 767px) {
              font-size: 18px;
            }
          }
          .ira-img {
            position: absolute;
            width: 150px;
            top: -50px;
            left: -25%;
            @media screen and (min-width: 768px) and (max-width: 1520px) {
              width: 100px;
              height: 110px;
              top: -120px;
              left: 30px;
            }
            @media screen and (max-width: 767px) {
              width: auto;
              height: 80px;
              top: -100px;
              left: 5px;
            }
            @media screen and (max-width: 350px) {
              display: none;
            }
          }
          .ira-img-right {
            position: absolute;
            width: 150px;
            top: -50px;
            right: -25%;
            @media screen and (min-width: 768px) and (max-width: 1520px) {
              width: 100px;
              height: 110px;
              top: -120px;
              right: 30px;
            }
            @media screen and (max-width: 767px) {
              width: auto;
              height: 80px;
              top: -100px;
              right: 5px;
            }
            @media screen and (max-width: 350px) {
              display: none;
            }
          }
        }
      }
      .annotation {
        font-size: 14px;
        text-align: center;
        color: var(--main);
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
      // 基本料金が安くなる項目
      .comparison-area {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .area {
          width: 30%;
          margin-bottom: 30px;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 48%;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          h4 {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            font-size: 20px;
            font-weight: 500;
            color: var(--white);
            background-color: var(--sub1);
            @media screen and (max-width: 767px) {
              font-size: 21px;
            }
            @media screen and (max-width: 767px) {
                font-size: 19px;
            }
          }
          .area-table {
            .row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              line-height: 20px;
              @media screen and (max-width: 767px) {
                font-size: 14px;
              }
              .content, .description {
                display: flex;
                align-items: center;
                align-content: center;
                height: 60px;
              }
              .content {
                width: 35%;
                padding-left: 10px;
                font-weight: 600;
                @media screen and (max-width: 767px) {
                  justify-content: center;
                  text-align: center;
                }
              }
              .fw-500 {
                font-weight: 500;
              }
              .description {
                justify-content: center;
                width: 30%;
              }
              .point-space {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35%;
                color: #CB6AD0;
                p {
                  font-weight: 600;
                  margin-right: 5px;
                }
                .point-img {
                  width: 30px;
                }
              }
            }
            .basic {
              background-color: var(--gray);
            }
            .toshigas {
              background-color: var(--sub3);
              color: var(--sub1);
              font-weight: 600;
            }
          }
        }
      }
      // 保険サービス
      .insurance {
        .insurance-message {
          text-align: center;
          margin: 30px 0;
          @media screen and (max-width: 767px) {
            text-align: start;
          }
        }
        .insurance-list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .service {
            position: relative;
            width: 45%;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              width: 47%;
            }
            @media screen and (max-width: 767px) {
              width: 100%;
              height: auto;
              margin-bottom: 30px;
            }
            .announce-text {
              position: absolute;
              top: -20px;
              left: -20px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 110px;
              width: 110px;
              text-align: center;
              font-size: 20px;
              line-height: 24px;
              font-weight: 400;
              border-radius: 999px;
              margin-bottom: 10px;
              color: var(--white);
              background-color: rgb(159, 111, 164);
              @media screen and (max-width: 1100px) {
                top: -15px;
                left: -15px;
              }
              .yen {
                font-size: 14px;
                font-weight: 500;
                margin-left: 2px;
              }
            }
            .service-img {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 250px;
              background-color: var(--gray);
              img {
                width: auto;
                height: 170px;
              }
            }
            .service-title {
              font-size: 25px;
              font-weight: 500;
              text-align: center;
              color: var(--sub1);
              margin: 30px 0 20px 0;
              @media screen and (max-width: 767px) {
                  font-size: 21px;
              }
            }
          }
        }
      }
      // お住まいの地域
      .residential-area {
        h4 {
          font-size: 25px;
          font-weight: 400;
          text-align: center;
          line-height: 30px;
          margin: 0 0 40px 0;
          color: var(--font);
          @media screen and (max-width: 1100px) {
            font-size: 20px;
            font-weight: 500;
            margin: 0 0 15px 0;
          }
          hr {
            width: 45px;
            margin: 20px auto 0 auto;
            border-color: var(--main);
            @media screen and (max-width: 1100px) {
              margin: 10px auto 30px auto;
            }
            @media screen and (max-width: 767px) {
              margin: 10px auto 20px auto;
            }
          }
        }
        .area-btn-list {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 80px;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            margin-bottom: 30px;
          }
          @media screen and (max-width: 767px) {
            margin-bottom: 40px;
          }
          .area-btn {
            display: flex;
            justify-content: center;
            align-items: end;
            align-content: end;
            flex-wrap: wrap;
            width: 23%;
            height: 80px;
            text-align: center;
            margin: 10px calc(8%/8);
            border-radius: 20px;
            border: none;
            color: var(--gray-text);
            background-color: var(--gray);
            &:hover {
              cursor: pointer;
              color: var(--white);
              background-color: var(--sub1);
            }
            @media screen and (max-width: 767px) {
              width: 32%;
              height: 50px;
              margin: 5px calc(4%/8);
            }
            span {
              width: 100%;
              font-size: 20px;
              @media screen and (max-width: 767px) {
                font-size: 14px;
              }
              @media screen and (max-width: 350px) {
                font-size: 12px;
              }
            }
            .area-text {
              font-size: 35px;
            }
          }
          .active {
            color: var(--white);
            background-color: var(--sub1);
          }
        }
        .toshigas-title {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 50px;
          font-size: 20px;
          color: var(--white);
          background-color: var(--sub1);
        }
        .toshigas-list {
          display: flex;
          .toshigas-basic-charge, .toshigas-electricity-charge {
            .title, .description {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              font-weight: 600;
              @media screen and (max-width: 767px) {
                flex-wrap: wrap;
              }
            }
            .title {
              height: 40px;
              font-weight: 400;
              color: var(--white);
              background-color: var(--sub2);
              margin: 0;
            }
            .description {
              height: 150px;
              font-weight: 500;
              color: var(--font);
              background-color: var(--sub3);
            }
          }
          .toshigas-basic-charge {
            width: 40%;
            border-right: 2px solid var(--white);
            .description {
              font-size: 22px;
              @media screen and (max-width: 767px) {
                align-content: center;
                font-size: 16px;
              }
              .price {
                color: var(--font);
                @media screen and (max-width: 767px) {
                  width: 100%;
                  text-align: center;
                }
              }
              .discount-img {
                width: 50px;
                margin-left: 10px;
                @media screen and (max-width: 767px) {
                  width: 40px;
                  margin: 0;
                }
              }
              span {
                color: var(--sub1);
              }
            }
          }
          .toshigas-electricity-charge {
            width: 60%;
            .description {
              font-size: 16px;
              @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 20px;
                text-align: center;
              }
            }
          }
        }
        .other-title {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 50px;
          font-size: 20px;
          color: var(--white);
          background-color: var(--gray-drak);
          margin-top: 50px;
        }
        .other-list {
          display: flex;
          .other-basic-charge, .other-electricity-charge {
            .title, .description {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              font-weight: 600;
            }
            .title {
              height: 40px;
              font-weight: 400;
              color: var(--white);
              background-color: var(--gray-text);
              margin: 0;
            }
            .description {
              height: 150px;
              font-weight: 500;
              color: var(--font);
              background-color: var(--gray);
            }
          }
          .other-basic-charge {
            width: 40%;
            border-right: 2px solid var(--white);
            .description {
              font-size: 22px;
              @media screen and (max-width: 767px) {
                font-size: 16px;
              }
              .discount-img {
                width: 50px;
                margin-left: 10px;
              }
              span {
                color: var(--sub1);
              }
            }
          }
          .other-electricity-charge {
            width: 60%;
            .description {
              flex-wrap: wrap;
              font-size: 16px;
              @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 20px;
                text-align: center;
              }
              .row {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 100%;
              @media screen and (max-width: 350px) {
                font-size: 12px;
              }
                .energy, .price {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 50%;
                }
              }
              .border {
                border-bottom: 2px solid var(--white);
              }
            }
          }
        }
        .breach-content {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          // align-content: center;
          padding: 20px;
          border: 5px solid var(--main);
          border-radius: 45px;
          margin-top: 40px;
          .breach-title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            height: 60px;
            margin: auto 0;
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            color: var(--white);
            background-color: var(--main);
            border-radius: 999px;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              width: 30%;
            }
            @media screen and (max-width: 767px) {
              width: 100%;
              margin-bottom: 10px;
            }
          }
          p {
            width: 75%;
            font-size: 16px;
            line-height: 25px;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              width: 65%;
            }
            @media screen and (max-width: 767px) {
              width: 100%;
            }
          }
        }
      }
    }
    .pt-80 {
      padding-top: 80px;
      @media screen and (max-width: 1100px) {
        padding-top: 40px;
      }
    }
    .pt-harf {
      padding-top: 60px;
      @media screen and (max-width: 1100px) {
        padding-top: 30px;
      }
    }
    .pt-0 {
      padding-top: 20px;
      @media screen and (max-width: 767px) {
        padding-top: 0;
      }
    }
    .pb-0 {
      padding-bottom: 0;
    }
  }
</style>